/* .stepper-wrapper {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.stepper-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  color: #4278b7;

  @media (max-width: 768px) {
    font-size: 12px;
  }
}

.stepper-item::before {
  position: absolute;
  content: "";
  border-bottom: 5px solid #ccc;
  width: 100%;
  top: 20px;
  left: -50%;
  z-index: 2;
}

.stepper-item::after {
  position: absolute;
  content: "";
  border-bottom: 5px solid #ccc;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 2;
}

.stepper-item .step-counter {
  position: relative;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #ffffff;
  border: solid 3px #4278b7;
  margin-bottom: 6px;
}

.stepper-item.active {
  font-weight: bold;
}

.stepper-item.active .step-counter {
  background-color: #4278b7;
}

.stepper-item.completed .step-counter {
  background-color: #ffffff;
}

.stepper-item.completed::after {
  position: absolute;
  content: "";
  border-bottom: 5px solid #4278b7;
  width: 100%;
  top: 20px;
  left: 50%;
  z-index: 3;
}

.stepper-item:first-child::before {
  content: none;
}

.stepper-item:last-child::after {
  content: none;
} */

#step-icon {
  width: 1.5rem;
  height: 1.5rem;
  margin: 3px 0;
  color: #4278b7;
}

.customer-content {
  margin: 2% 20%;
  background-color: #ebecee;
  /* position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%; */
}

.header {
  background-color: #262262;
  color: #FFFFFF !important;
  padding: 20px 4px 100px 4px;
  text-align: center;
  border-radius: 5px 5px 0 0;
  position: relative !important;
  z-index: 0;
}

.header-logo {
  text-align: center;
  margin: -100px auto 20px auto;
  position: relative !important;
  z-index: 2 !important;
  padding: 20px;
  background-color: #ffffff;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  vertical-align: middle;
}

.header-titile {
  text-align: center;
  padding-top: 50px;
  font-size: 1.5em;
  color: #6d6e71;
  font-weight: bold;
}

.text-feld-hint {
  color: #adb5bd;
  font-size: 0.8em;
}

.form-group {
  margin-bottom: 0px !important;
  margin-top: 10px !important;
}

.start-content {
  text-align: center;
  margin-bottom: 60px;
  font-size: 1.2em;
  color: #6d6e71;
  font-weight: bold;
}

#tenant_logo {
  width: 120px;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.footer {
  text-align: center;
  color: #6d6e71 !important;
  padding-bottom: 15px;
  position: relative;
  bottom: 0;
}

.footer-button {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 200px;
}

#vemaslogo_footer {
  width: 100px;
}

.start {
  text-align: center;
  margin-bottom: 100px;
}

.content {
  margin: 0 10%;
  padding: 0 10%;
}

.step-bar {
  text-align: center;
  margin-bottom: 20px;
}

.step-bar-title {
  color: #2c2c2c;
  font-size: 0.8em;
}

.dot-bar {
  display: flex;
  justify-content: center;
}

.active-dot {
  border-radius: 17px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #262262;
  width: 45px;
  height: 15px;
  margin: 10px 5px;
}

.next-dot {
  width: 15px;
  height: 15px;
  margin: 10px 5px;
  border-radius: 17px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #6d6e71;
}

.prev-dot {
  width: 15px;
  height: 15px;
  margin: 10px 5px;
  border-radius: 17px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #262262;
}

.email {
  margin: 80px 10%;
}

.mobile {
  margin: 80px 10%;
}

.name {
  margin: 50px 10%;
}

.confirm-details {
  border-radius: 10px;
  background-color: #d9d9d9;
  margin: 5px 0;
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;
}

.confirm-details-title {
  color: #262262;
  font-weight: 500;
  margin: 2px;
}

.confirm-details-detail {
  color: #6d6e71;
  padding-left: 5px;
}

#confirm-correct-icon {
  width: 25px;
  margin-top: 10px;
}

.start {
  display: flex;
  justify-content: center;
}

.start-welcome {
  display: flex;
  justify-content: center;
}

.start-welcome h4 {
  font-weight: 700;
  color: #ffffff;
  margin: 80px auto 30px auto;
}

#thumb-up {
  width: 120px;
  margin: 50px 20px 20px 20px;
  background-color: #217139;
  border-radius: 50%;
  padding: 20px;
}

.success-content {
  display: inline;
  justify-content: center;
  margin: 100px 5px;
  text-align: center;
  color: #4278b7;
}

.success-content h3,
h5 {
  font-weight: 600;
  color: #262262;
}

.success-logo {
  text-align: center;
  margin: 10px auto 20px auto;
  position: relative !important;
  z-index: 2 !important;
  padding: 20px;
  background-color: #ffffff;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  vertical-align: middle;
}

.success-titile {
  text-align: center;
  padding: 30px 0;
  font-size: 1.5em;
  color: #6d6e71;
  font-weight: bold;
  line-height: 22px;
}

.success-titile small{
  font-size: 0.8em;
}

@media screen and (max-width: 700px) {
  .customer-content {
    margin: 2%;
  }

  .header h3 {
    font-size: 20px;
  }

  /* .start {
    margin: 100px 5px 200px 5px;
  } */

  .logOut {
    margin: 10px 10px 0 0;
  }

  .email {
    margin: 100px 2%;
  }

  .mobile {
    margin: 100px 2%;
  }

  .name {
    margin: 40px 2%;
  }

  .content {
    margin: 0 2%;
    padding: 0 5%;
  }
}